<template>
  <div class="wap-shop-level">
    <headerBar :title="$t('mai-jia-deng-ji-2')" @back="back"></headerBar>
    <div class="top-banner">
      <img :src="bannerUrl" alt="" />
    </div>

    <div class="level-list">
      <div class="list" v-for="(item, i) in levelList" :key="i">
        <div class="flex-center-between top">
          <div>
            <div class="name">
              {{ item.LevelName }}
              {{ $t('mai-jia-deng-ji-0') }}
            </div>
            <div class="label">
              {{ $t('yun-hang-zi-jin') }}
              ${{ item.UpgradeMoney }}
            </div>
          </div>
          <div>
            <img :src="logoObj[`logo${item.Level}`] || packageUrl" alt="" />
          </div>
        </div>
        <div class="info">
          <div class="line">
            <div class="label">{{ $t('xiao-shou-li-run-bi') }}</div>
            <div class="value">
              {{ item.ProfitRate }}%-{{ item.ProfitRate }}%
            </div>
          </div>
          <div class="line">
            <div class="label">
              {{ $t('ping-tai-liu-liang-fu-chi-liang-mei-ri') }}
            </div>
            <div class="value">{{ item.GoodsLimit }}-{{ item.GoodsLimit }}</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('quan-qiu-dao-huo-shi-jian') }}</div>
            <div class="value">{{ item.date }}</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('cai-gou-you-hui') }}</div>
            <div class="value">{{ item.discount }}%</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('sheng-ji-li-jin') }}</div>
            <div class="value">
              <i
                class="el-icon-success"
                style="color: #0ecb81"
                v-if="item.upgrade"
              ></i>
              <i class="el-icon-error" style="color: #e23939" v-else></i>
            </div>
          </div>
          <div class="line">
            <div class="label">{{ $t('zhuan-shu-fu-wu') }}</div>
            <div class="value">
              <i
                class="el-icon-success"
                style="color: #0ecb81"
                v-if="item.vip"
              ></i>
              <i class="el-icon-error" style="color: #e23939" v-else></i>
            </div>
          </div>
          <div class="line">
            <div class="label">{{ $t('shou-ye-tui-jian') }}</div>
            <div class="value">
              <i
                class="el-icon-success"
                style="color: #0ecb81"
                v-if="item.recommend"
              ></i>
              <i class="el-icon-error" style="color: #e23939" v-else></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { shopLevelList } from '@/api/shop'
import headerBar from '@/components/header'
import { Button } from 'vant'
import { refundList } from '@/api/shop'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      levelList: [],
      bannerUrl: require('@/assets/imgs/level-bg.png'),
      packageUrl: require('@/assets/imgs/shop-level1.png'),
      logoObj: {
        logo1: require('@/assets/imgs/shop-level1.png'),
        logo2: require('@/assets/imgs/shop-level2.png'),
        logo3: require('@/assets/imgs/shop-level3.png'),
        logo4: require('@/assets/imgs/shop-level4.png'),
        logo5: require('@/assets/imgs/shop-level5.png'),
        logo6: require('@/assets/imgs/shop-level6.png')
      }
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      shopLevelList().then((res) => {
        this.levelList = res.data.Items
        this.levelList.sort((a, b) => {
          return a.Level - b.Level
        })
      })
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>